import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "ProgressBar",
  code: "ProgressBar"
};
export const _frontmatter = {
  "menuLabel": "Progress Bar",
  "sortOrder": 16.3
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Progress Bar`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-progress-bar--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ProgressBar } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`A progress bar component visually represents the completion status of a task or process, providing users with real-time feedback on their progress. This horizontal bar fills up from left to right, often accompanied by percentage indicators or descriptive text to enhance clarity. `}</p>
    <p>{`It can also be used to display the time required for tasks such as file uploads, form submissions, or software installations.`}</p>
    <p>{`In a betting context, it can show a bet's live progress during a game, highlighting key thresholds that need to be met for a bettor to win or lose. This feature adds real-time engagement, making it an essential tool for setting clear expectations and keeping users informed throughout the event.`}</p>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The progress bar uses high contrast colors to ensure visibility.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        Users can interact with the Progress Bar using a variety of input methods, including keyboard and mouse. The ProgressBar also has a consistent behavior across the site, so that users can anticipate what will happen when they click on it.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        Uses clear, concise labels and descriptions for the progress bar when necessary.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        Uses standard HTML, ARIA roles, and properties for progress bars.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      